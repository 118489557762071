import * as React from 'react'
import { LocalizedLink as Link } from 'gatsby-theme-i18n'
import Seo from '../../components/common/SEO/Seo'

const pageStyles = {
  color: '#232129',
  padding: '96px',
  fontFamily: '-apple-system, Roboto, sans-serif, serif'
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320
}
const paragraphStyles = {
  marginBottom: 48
}
const codeStyles = {
  color: '#8A6534',
  padding: 4,
  backgroundColor: '#FFF4DB',
  fontSize: '1.25rem',
  borderRadius: 4
}

const NotFoundPage = () => (
  <>
    <Seo
      title='Fehlerseite | the.good.code;'
      description='Es ist etwas schief gelaufen und wir arbeiten daran, das Problem zu beheben. Bitte versuchen Sie es später noch einmal.'
    />
    <main style={pageStyles}>
      <h1 style={headingStyles}>Seite nicht gefunden</h1>
      <p style={paragraphStyles}>
        Tut uns leid 😔, wir konnten nicht finden, wonach Sie gesucht haben.
        <br />
        {process.env.NODE_ENV === 'development' ? (
          <>
            <br />
            Try creating a page in <code style={codeStyles}>src/pages/</code>.
            <br />
          </>
        ) : null}
        <br />
        <Link to='/'>Zur Startseite</Link>.
      </p>
    </main>
  </>
)

export default NotFoundPage
